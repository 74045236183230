import React, { FunctionComponent } from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import Subheader from "../components/subheader/subheader"
import { Category } from "../models/category"
import { Card } from "../components/card/card"
import slugify from "slugify"
import { Grid } from "../components/common/common"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/seo/seo"
import * as S from "../components/pages/categories/categories.style"

interface CategoriesPageProps {
  data: {
    allCategories: {
      edges: Array<{ node: Category }>
    }
  }
  location: Location
}

const CategoryPage: FunctionComponent<CategoriesPageProps> = ({
  data,
  location,
}) => {
  const categories = data.allCategories.edges.map(node => node.node)

  return (
    <Layout bigHeader={false}>
      <SEO location={location} title={`Categorias`} type={`Series`} />
      <Subheader
        title={`Categoria`}
        subtitle={`${categories.length} categorias`}
      />
      <Grid columns={6}>
        {categories.map((category, index) => (
          <Card
            key={index}
            path={`/categoria/${slugify(category.name, { lower: true })}`}
            compact={true}
            style={{ textAlign: "center" }}
          >
            {category.icon.extension !== "svg" ? (
              <GatsbyImage
                image={getImage(category.icon)}
                alt={category.name}
              />
            ) : (
              <S.CategorySvgIcon
                src={category.icon.publicURL}
                alt={category.name}
              />
            )}
            <S.CategoryName>{category.name}</S.CategoryName>
          </Card>
        ))}
      </Grid>
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
  query {
    allCategories {
      edges {
        node {
          name
          icon {
            childImageSharp {
              gatsbyImageData(height: 55)
            }
            extension
            publicURL
          }
        }
      }
    }
  }
`
